<template>
  <div class="container">
    <div class="periphery">
      <span>组织名称：</span>
      <div style="display: inline-block; width: 25%;">
        <el-input
          placeholder="请输入内容"
          v-model="input2"
          size="small"
          clearable
          @keyup.enter.native="search"
          style="width: 80%"
        >
          <template slot="append">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </template>
        </el-input>
        <el-button
          type="warning"
          size="small"
          style="margin-left: 10px"
          @click="reset"
          icon="el-icon-refresh"
          >重置
        </el-button>
      </div>
    </div>

    <div class="periphery" style="margin-top: 15px">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="insert"
        >新增</el-button
      >
      <el-button
        type="warning"
        size="small"
        style="margin-left: 10px"
        @click="getData(params)"
        icon="el-icon-refresh"
        >刷新
      </el-button>
      <div style="margin-top: 15px">
        <el-table stripe :data="list">
          <el-table-column
            label="组织成员名称"
            prop="leaderName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="工作职级" prop="jobLevel"></el-table-column>
          <el-table-column label="创建人" prop="userName"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="getEdit(scope.row.id)"
                type="primary"
                >编辑</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="deleteMeans(scope.row.id)"
                size="mini"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      class="pagination"
      background
      :total="total"
      :current-page="params.pageNum"
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size="params.pageSize"
      :page-sizes="[10, 20, 50, 100]"
    ></el-pagination>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :model="form"
        class="fuck-form"
        size="mini"
        :rules="ruleForm"
        label-width="100px"
      >
        <el-form-item label="成员名称" prop="name">
          <el-input
            v-model="form.name"
            clearable
            class="subassembly"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作级别" prop="">
          <el-input
            v-model="form.level"
            clearable
            class="subassembly"
            placeholder="请输入级别"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作简介" prop="">
          <el-input
            v-model="form.describe"
            clearable
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作经历" prop="">
          <el-input
            v-model="form.experience"
            clearable
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div style="text-align: right;">
            <el-button type="primary" size="small" @click="submitForm('form')"
              >确 定</el-button
            >
            <el-button size="small" @click="resetForm('form')">关 闭</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { messageBox } from "@/util/index";
export default {
  name: "details2",
  data() {
    return {
      input2: "",
      list: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        title: "",
        uid: "",
        orgId: null
      },
      total: 0,
      title: "编辑组织成员信息管理",
      dialogVisible: false,
      form: {
        name: "",
        level: "",
        describe: "",
        experience: ""
      },
      ruleForm: {},
      id: null
    };
  },
  mounted() {
    this.params.orgId = this.$route.params.id;
    this.getData(this.params);
  },
  methods: {
    /**
     * 条件搜索
     */
    search() {
      this.params.title = this.input2;
      this.getData(this.params);
    },
    /**
     * 重置
     */
    reset() {
      this.input2 = "";
    },
    /**
     * 获取数据
     * @param data
     */
    getData(data) {
      this.postForm(this.config.apiUrl.organizationTable, data).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.list = res.rows.map(item => {
            item.commentCount = item.commentCount ? item.commentCount : 0;
            return item;
          });
          this.total = res.total;
        } else {
          this.msgError(res.msg);
        }
      });
    },
    /**
     * pageSize 每页条数
     * @param val
     */
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    /**
     * 当前页
     * @param val
     */
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getData(this.params);
    },
    /**
     * 点击图标关闭弹框
     */
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * 提交
     * @param formName
     */
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {};
          if (this.id) {
            data = {
              leaderName: this.form.name,
              jobLevel: this.form.level,
              jobInfo: this.form.describe,
              govId: this.$route.params.id,
              id: this.id,
              jobExperience: this.form.experience
            };
          } else {
            data = {
              leaderName: this.form.name,
              jobLevel: this.form.level,
              jobInfo: this.form.describe,
              govId: this.$route.params.id,
              jobExperience: this.form.experience
            };
          }

          console.log(data);
          this.updateEvent(data);
        } else {
          return false;
        }
      });
    },
    /**
     * 关闭
     * @param formName
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form.name = "";
      this.form.level = "";
      this.form.describe = "";
      this.form.experience = "";
      this.id = null;
      this.dialogVisible = false;
    },
    /**
     * 获取组织成员详情
     * @param id
     */
    getEdit(id) {
      this.get(`${this.config.apiUrl.membershipDetails}${id}/info`).then(
        res => {
          console.log(res);
          if (res.code == 0) {
            this.id = id;
            this.dialogVisible = true;
            this.form.name = res.data.leaderName;
            this.form.level = res.data.jobLevel;
            this.form.describe = res.data.jobInfo;
            this.form.experience = res.data.jobExperience;
          } else {
            this.msgError(res.msg);
          }
        }
      );
    },
    /**
     * 新增或者修改组织成员
     * @param data
     */
    updateEvent(data) {
      this.postForm(this.config.apiUrl.saveorupdate, data).then(res => {
        if (res.code == 0) {
          this.msgSuccess(res.msg);
          this.getData(this.params);
          this.resetForm("form");
        } else {
          this.msgError(res.msg);
        }
      });
    },
    /**
     * 添加按钮事件
     **/
    insert() {
      this.dialogVisible = true;
      this.title = "新增组织成员信息管理";
    },
    /**
     * 删除组织成员
     * @param id
     */
    deleteMeans(id) {
      messageBox(
        "此操作将永久删除该文件, 是否继续?",
        "提示",
        this.deleteClick,
        id
      );
    },
    /**
     * 删除组织成员接口
     * @param id
     */
    deleteClick(id) {
      this.postForm(this.config.apiUrl.removeOrganizationMembers, {
        ids: id
      }).then(res => {
        if (res.code == 0) {
          this.msgSuccess(res.msg);
          this.getData(this.params);
        } else {
          this.msgError(res.msg);
        }
      });
    }
  }
};
</script>

<style scoped></style>
