<template>
    <div>
        <el-row>
            <el-col
                    :span="8"
                    style="line-height: 20px; padding: 3px 0"
                    v-for="(item, index) in list"
                    :key="index"
            >
                <div @click="selectedIcon(item)">
                    <i :class="item.icon"></i>
                    <span class="font">{{ item.text }}</span>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
	import axios from "axios";

	export default {
		name: "icon",
		data() {
			return {
				list: []
			};
		},
		mounted() {
			this.Get();
		},
		methods: {
			/**
			 * 获取icon
			 * @constructor
			 */
			Get() {
				axios.get("/data/icon-data.json").then(res => {
					if (res.status == 200) {
						console.log(res.data.list);
						this.list = res.data.list;
					} else {
						this.msgError(res.statusText);
					}
				});
			},
			/**
			 * 选中icon
			 * @param item
			 */
			selectedIcon(item) {
				this.$emit("selected", item);
				document.body.click();
			}
		}
	};
</script>

<style lang="scss" scoped>
    i {
        font-size: 20px;
        cursor: pointer;
    }

    .font {
        vertical-align: top;
        font-size: 12px;
        cursor: pointer;
    }
</style>
