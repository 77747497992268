<template>
  <div class="container">
    <div class="periphery">
      <span>组织名称：</span>
      <div class="condition-style">
        <el-input
          placeholder="请输入内容"
          v-model="input2"
          size="small"
          clearable
          @keyup.enter.native="search"
          style="width: 80%"
        >
          <template slot="append">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </template>
        </el-input>
        <el-button
          type="warning"
          size="small"
          style="margin-left: 10px"
          @click="reset"
          icon="el-icon-refresh"
          >重置
        </el-button>
      </div>
    </div>

    <div class="periphery" style="margin-top: 15px">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="insert"
        >新增</el-button
      >
      <el-button
        type="warning"
        size="small"
        style="margin-left: 10px"
        @click="getData(params)"
        icon="el-icon-refresh"
        >刷新
      </el-button>
      <div style="margin-top: 15px">
        <el-table stripe :data="list">
          <el-table-column
            label="组织名称"
            prop="govName"
            min-width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <el-table-column label="创建人" prop="userName"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="update(scope.row.id)"
                type="primary"
                >编辑</el-button
              >
              <el-button
                type="primary"
                icon="el-icon-view"
                @click="getEdit(scope.row.id)"
                size="mini"
                >查看组织成员</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="deleteMeans(scope.row.id)"
                size="mini"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      class="pagination"
      background
      :total="total"
      :current-page="params.pageNum"
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size="params.pageSize"
      :page-sizes="[10, 20, 50, 100]"
    ></el-pagination>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :model="form"
        class="fuck-form"
        size="mini"
        :rules="ruleForm"
        label-width="120px"
      >
        <el-form-item label="组织名称" prop="name">
          <el-input
            v-model="form.name"
            clearable
            class="subassembly"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="组织描述" prop="">
          <el-input
            v-model="form.describe"
            clearable
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div style="text-align: right;">
            <el-button type="primary" size="small" @click="submitForm('form')"
              >确 定</el-button
            >
            <el-button size="small" @click="resetForm('form')">关 闭</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/util/token";
import { messageBox } from "@/util/index";

export default {
  name: "index",
  data() {
    return {
      input2: "",
      list: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        title: "",
        uid: ""
      },
      total: 0,
      title: "添加组织",
      dialogVisible: false,
      form: {
        name: "",
        describe: ""
      },
      ruleForm: {},
      villageId: null,
      id: null
    };
  },
  mounted() {
    this.getData(this.params);
    this.getOrgId();
  },
  methods: {
    /**
     * 获取数据
     * @param data
     */
    getData(data) {
      this.postForm(this.config.apiUrl.mapper, data).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.list = res.rows;
          this.total = res.total;
        } else {
          this.msgError(res.msg);
        }
      });
    },
    /**
     * 条件搜索
     */
    search() {
      this.params.title = this.input2;
      this.getData(this.params);
    },
    /**
     * 重置
     */
    reset() {
      this.input2 = "";
    },
    /**
     * pageSize 每页条数
     * @param val
     */
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    /**
     * 当前页
     * @param val
     */
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getData(this.params);
    },
    /**
     * 获取公告详情
     * @param id
     */
    getEdit(id) {
      this.$router.push({
        path: `organizationDetails/${id}`
      });
    },
    /**
     * 点击图标关闭弹框
     */
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * 提交
     * @param formName
     */
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {};
          if (this.id) {
            data = {
              govName: this.form.name,
              govContent: this.form.describe,
              villageId: this.villageId,
              id: this.id
            };
          } else {
            data = {
              govName: this.form.name,
              govContent: this.form.describe,
              villageId: this.villageId
            };
          }

          console.log(data);
          this.updateEvent(data);
        } else {
          return false;
        }
      });
    },
    /**
     * 关闭
     * @param formName
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form.describe = null;
      this.dialogVisible = false;
      this.id = null;
    },
    /**
     * 编辑或者新增组织
     * @param data
     */
    updateEvent(data) {
      this.postForm(this.config.apiUrl.orgSaveorupdate, data).then(res => {
        if (res.code == 0) {
          this.msgSuccess(res.msg);
          this.getData(this.params);
          this.resetForm("form");
        } else {
          this.msgError(res.msg);
        }
      });
    },
    /**
     * 根据管理员所在的村子获取村子编号
     */
    getOrgId() {
      this.get(this.config.apiUrl.orgId).then(res => {
        if (res.code == 0) {
          this.villageId = res.data.id;
        } else {
          this.msgError(res.msg);
        }
      });
    },
    /**
     * 删除组织成员
     * @param id
     */
    deleteMeans(id) {
      messageBox(
        "此操作将永久删除该文件, 是否继续?",
        "提示",
        this.deleteClick,
        id
      );
    },
    /**
     * 删除组织成员接口
     * @param id
     */
    deleteClick(id) {
      this.postForm(this.config.apiUrl.orgRemove, {
        ids: id
      }).then(res => {
        if (res.code == 0) {
          this.msgSuccess(res.msg);
          this.getData(this.params);
        } else {
          this.msgError(res.msg);
        }
      });
    },
    /**
     * 列表编辑事件
     * @param id
     */
    update(id) {
      this.id = id;
      this.dialogVisible = true;
      this.title = "编辑组织信息";
      this.getOrgDetails(id);
    },
    /**
     * 添加按钮事件
     **/
    insert() {
      this.dialogVisible = true;
      this.title = "添加组织信息";
    },
    /**
     * 编辑前查看组织成员详情
     * @param id
     */
    getOrgDetails(id) {
      this.get(this.config.apiUrl.orgDetails + id + "/info").then(res => {
        if (res.code == 0) {
          console.log(res);
          this.form.name = res.data.govName;
          this.form.describe = res.data.govContent;
        } else {
          this.msgError(res.msg);
        }
      });
    }
  }
};
</script>

<style scoped></style>
