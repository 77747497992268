<template>
  <div>主页</div>
</template>

<script>
export default {
  name: "index"
};
</script>

<style scoped></style>
