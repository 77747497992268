<template>
  <div class="container">
    <div class="periphery">
      <span>姓名：</span>
      <div class="condition-style">
        >
        <el-input
          placeholder="请输入内容"
          v-model="input2"
          size="small"
          clearable
          @keyup.enter.native="search"
          style="width: 80%"
        >
          <template slot="append">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </template>
        </el-input>
        <el-button
          type="warning"
          size="small"
          style="margin-left: 10px"
          @click="reset"
          icon="el-icon-refresh"
          >重置
        </el-button>
      </div>
    </div>

    <div class="periphery" style="margin-top: 15px">
      <div style="margin-top: 15px">
        <el-table stripe :data="list">
          <el-table-column
            label="登录人"
            prop="login_name"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="用户名" prop="user_name"></el-table-column>
          <el-table-column
            label="身份证号码"
            prop="id_card_no"
          ></el-table-column>
          <el-table-column label="审核状态" prop="audit_state">
            <template slot-scope="scope">
              <el-tag effect="dark" :type="status(scope.row.audit_state).type">
                {{ status(scope.row.audit_state).text }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="推荐人手机号" prop="phone"></el-table-column>
          <el-table-column
            label="申请村子"
            prop="hometown_village"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-message"
                @click="getEdit(scope.row.id)"
                size="mini"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      class="pagination"
      background
      :total="total"
      :current-page="params.pageNum"
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size="params.pageSize"
      :page-sizes="[10, 20, 50, 100]"
    ></el-pagination>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :model="form"
        class="fuck-form"
        size="mini"
        :rules="ruleForm"
        label-width="120px"
      >
        <el-form-item label="申请人" prop="name">
          <el-input
            v-model="form.name"
            :disabled="true"
            clearable
            class="subassembly"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请时间" prop="">
          <el-date-picker
            v-model="form.date"
            :disabled="true"
            type="date"
            style="width: 50%"
            value-format="yyyy-mm-dd HH:mm:ss"
            format="yyyy-mm-dd HH:mm:ss"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="用户身份证号码" prop="">
          <el-input
            v-model="form.ID"
            :disabled="true"
            clearable
            class="subassembly"
            placeholder="请输入级别"
          ></el-input>
        </el-form-item>
        <el-form-item label="推荐人电话" prop="">
          <el-input
            v-model="form.phone"
            :disabled="true"
            class="subassembly"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="家庭地址" prop="">
          <el-input
            v-model="form.address"
            :disabled="true"
            class="subassembly"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="是否通过" prop="">
          <el-select
            v-model="form.pass"
            placeholder="请选择评论状态"
            clearable
            class="subassembly"
          >
            <el-option label="成功" value="2"></el-option>
            <el-option label="驳回" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核不通过原因" prop="">
          <el-input
            v-model="form.cause"
            clearable
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div style="text-align: right;">
            <el-button type="primary" size="small" @click="submitForm('form')"
              >确 定</el-button
            >
            <el-button size="small" @click="resetForm('form')">关 闭</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      input2: "",
      list: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        title: "",
        uid: ""
      },
      total: 0,
      title: "修改帖子管理",
      dialogVisible: false,
      form: {
        name: "",
        date: null,
        ID: "",
        phone: "",
        address: "",
        pass: "2",
        cause: ""
      },
      ruleForm: {},
      id: null
    };
  },
  mounted() {
    this.getData(this.params);
  },
  methods: {
    /**
     * 获取数据
     * @param data
     */
    getData(data) {
      this.postForm(this.config.apiUrl.checkList, data).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.list = res.rows;
          this.total = res.total;
        } else {
          this.msgError(res.msg);
        }
      });
    },
    /**
     * 条件搜索
     */
    search() {
      this.params.title = this.input2;
      this.getData(this.params);
    },
    /**
     * 重置
     */
    reset() {
      this.input2 = "";
    },
    /**
     * pageSize 每页条数
     * @param val
     */
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    /**
     * 当前页
     * @param val
     */
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getData(this.params);
    },
    /**
     * 状态
     * @param status
     * @returns {string}
     */
    status(status) {
      let str = {};
      if (status == 0) {
        str.text = "新建";
        str.type = "";
      } else if (status == 1) {
        str.text = "提交";
        str.type = "info";
      } else if (status == 2) {
        str.text = "成功";
        str.type = "success";
      } else {
        str.text = "驳回";
        str.type = "danger";
      }
      return str;
    },
    /**
     * 点击图标关闭弹框
     */
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * 获取家乡审核管理详情
     * @param id
     */
    getEdit(id) {
      this.get(`${this.config.apiUrl.homeAuditDetails}${id}`).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.id = id;
          this.dialogVisible = true;
          this.form.name = res.data.nickName;
          this.form.date = res.data.applyTime;
          this.form.ID = res.data.cardId;
          this.form.phone = res.data.hometownRecommenderPhone;
          this.form.address =
            res.data.hometownProvince +
            res.data.hometownCity +
            res.data.hometownCounty +
            res.data.hometownTown +
            res.data.hometownVillage;
          this.form.cause = res.data.auditFail;
        } else {
          this.msgError(res.msg);
        }
      });
    },
    /**
     * 提交
     * @param formName
     */
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            id: this.id,
            state: this.form.pass,
            auditFail: this.form.cause
          };

          console.log(data);
          this.updateEvent(data);
        } else {
          return false;
        }
      });
    },
    /**
     * 关闭
     * @param formName
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    /**
     * 审核状态
     * @param data
     */
    updateEvent(data) {
      this.postForm(this.config.apiUrl.homeAuditCheck, data).then(res => {
        if (res.code == 0) {
          this.msgSuccess(res.msg);
          this.getData(this.params);
          this.dialogVisible = false;
        } else {
          this.msgError(res.msg);
        }
      });
    }
  }
};
</script>

<style scoped></style>
